.pagination .page-link {
  z-index: 3;
  width: 30px;
  height: 30px;
  color: #6c757d;
  background: transparent
    linear-gradient(256deg, #d5a340 0%, #fcf089 51%, #b98434 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 0px 4px #ecd06b;
  border-color: #d5a340 !important;
  border: 1px solid #d5a340;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  margin: 0px 3px;
  cursor: pointer;
  border-radius: 50px !important;
}

.pagination .page-link.active {
  z-index: 3;
  border-color: #d5a340;
  width: 30px;
  height: 30px;
  background: transparent
    linear-gradient(256deg, #d5a340 0%, #fcf089 51%, #b98434 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 0px 4px #ecd06b;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  margin: 0px 3px;
  cursor: pointer;
  border-radius: 50px !important;
}

.pagination .page-item.active .page-link {
  color: #000;
}

.pagination {
  justify-content: end;
}

.pagination .prev-button .page-link {
  border-radius: 50px !important;
  color: #dee2e68a;
  background: #2a0401;
  cursor: not-allowed;
  font-weight: 700;
}

.pagination .next-button .page-link {
  border-radius: 50px !important;
  color: #dee2e68a;
  background: #2a0401;
  cursor: not-allowed;
  font-weight: 700;
}
