.Profile_Tab .tab-row {
  margin: 30px 0px;
  border-bottom: 2px solid #707070;
  flex-wrap: nowrap;
  overflow: hidden;
  padding: 5px 5px 0px 5px;
  overflow-x: auto;
}



.Profile_Tab .tab-row .nav-link {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background: #15171a;
  /* box-shadow: 0px 0px 4px #ecd06b; */
  padding: 8px 15px;
  margin-right: 15px;
  border-radius: 2px;
  text-wrap-mode: nowrap;
}

.Profile_Tab .tab-row .nav-link.active {
  color: #fff;
  background: #343a40;
}

.Profile_Tab .tab-row .nav-item {
  margin-right: 15px;
}
