.User_List_Table .table-frame-holder {
  height: 600px;
}

.User_List_Table .table-frame-holder .table-frame-img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/0.4;
  display: none;
}
