/* .Buckets {
    background: transparent
    linear-gradient(180deg, #2a0401 0%, #b20f02 32%, #320701 66%, #360701 100%)
    0% 0% no-repeat padding-box;
    box-shadow: inset 0px 0px 8px #ecd06b;
    width: 100%;
    height: 100%;
    min-height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 15px;
} */

.Buckets {
  /* background-image: url("/public/Icon/bucket-bg.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
}
.Dashboard .Buckets:nth-child(1)  {
  background:linear-gradient(220deg, #ff0000 0%, #00000000 28%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 4px #ff000029;
  border: 1px solid #ff000045;
  background-color: #090909;
}

/* .Buckets > .bucket-content:nth-child(1) {
  background:linear-gradient(220deg, #ff0000 0%, #00000000 28%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 4px #ff000029;
  border: 1px solid #ff000045;
  background-color: #090909;
} */

/* .Buckets > .bucket-content:nth-child(2) {
  background:linear-gradient(220deg, #ecd06b 0%, #00000000 28%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 4px #ff000029;
  border: 1px solid #ff000045; 
  background-color: #090909;
} */


.Buckets .bucket-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.Buckets .buckets-text h3 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  padding: 0px 10px;
  font-family: unset !important;
  margin-bottom: 50px;
}

.Buckets .vr-border:before {
  content: "";
  background: transparent linear-gradient(180deg, #913c09 0%, #491e05 100%) 0% 0% no-repeat padding-box;
  position: absolute;
  top: -5px;
  left: 50%;
  right: 50%;
  width: 250px;
  height: 11px;
  transform: translate(-50%, -50%);
}

.Buckets .vr-border:after {
  content: "";
  background: transparent linear-gradient(180deg, #913c09 0%, #491e05 100%) 0% 0% no-repeat padding-box;
  position: absolute;
  bottom: -16px;
  left: 50%;
  right: 50%;
  width: 250px;
  height: 11px;
  transform: translate(-50%, -50%);
}

.Buckets .hr-border:before {
  content: "";
  background: transparent linear-gradient(180deg, #913c09 0%, #491e05 100%) 0% 0% no-repeat padding-box;
  position: absolute;
  top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  left: -5px;
  width: 11px;
  height: 111%;
  min-height: 212px;
  max-height: 111%;
}

.Buckets .hr-border::after {
  content: "";
  background: transparent linear-gradient(180deg, #913c09 0%, #491e05 100%) 0% 0% no-repeat padding-box;
  position: absolute;
  top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  right: -16px;
  width: 11px;
  height: 111%;
  min-height: 212px;
  max-height: 111%;
}

.Buckets .vr-border .border-holder:after {
  position: absolute;
  content: "";
  border-top: 3px dotted #491e05;
  filter: blur(1px);
  top: 102%;
  width: 114%;
  height: fit-content;
  z-index: 1;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
}

.Buckets .vr-border .border-holder:before {
  position: absolute;
  content: "";
  border-top: 3px dotted #491e05;
  filter: blur(1px);
  bottom: 102%;
  width: 114%;
  height: fit-content;
  z-index: 1;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
}

.Buckets .hr-border .border-holder:after {
  position: absolute;
  content: "";
  border-right: 3px dotted #491e05;
  filter: blur(1px);
  left: 102%;
  width: fit-content;
  z-index: 1;
  top: 50%;
  bottom: 50%;
  transform: translate(0%, -50%);
  height: 111%;
  min-height: 214px;
  max-height: 109%;
}

.Buckets .hr-border .border-holder:before {
  position: absolute;
  content: "";
  border-right: 3px dotted #491e05;
  filter: blur(1px);
  right: 102%;
  width: fit-content;
  z-index: 1;
  top: 50%;
  bottom: 50%;
  transform: translate(0%, -50%);
  height: 111%;
  min-height: 214px;
  max-height: 109%;
}

@media (min-width: 575px) and (max-width: 767px) {
  .Buckets .buckets-text h3 {
    text-align: center;
    font-size: 15px;
    
    padding: 0px 39px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Buckets .buckets-text h3 {
    text-align: center;
    font-size: 15px;
   
    padding: 0px 39px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .Buckets .buckets-text h3 {
    text-align: center;
    font-size: 16px;
   
    padding: 0px 39px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .Buckets .buckets-text h3 {
    text-align: center;
    font-size: 16px;
   
    padding: 0px 39px;
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .Buckets .buckets-text h3 {
    text-align: center;
    font-size: 16px;
   
    padding: 0px 39px;
  }
}

@media (min-width: 1300px) and (max-width: 1399px) {
  .Buckets .buckets-text h3 {
    text-align: center;
    font-size: 16px;
    
    padding: 0px 39px;
  }
}

@media (min-width: 1400px) and (max-width: 1499px) {
  .Buckets .buckets-text h3 {
    text-align: center;
    font-size: 18px;
    padding: 0px 10px;
}
}