.Total_Amount_info {
  margin-bottom: 30px;
}

.Total_Amount_info .info-row {
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.Total_Amount_info .count-info-holder .text-holder h3 {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 20px;
}

.Total_Amount_info .Border_div .sign-up-btn {
  font-size: 25px;
  font-weight: 700;
  padding: 2px 18px;
  border-radius: 4px;
}

.Total_Amount_info .Border_div .sign-up-btn:after {
  min-width: 137px;
  height: 51px;
  border: 3px dotted #f9e67f;
}

.Total_Amount_info .heading-holder h3 {
  margin-bottom: 25px;
}

.Total_Amount_info .count-info-holder {
  background: #14141b;
  box-shadow: 0px 0px 6px #00000082;
  border-radius: 6px 0px 0px 6px;
  height: 100%;
  padding: 25px 0px;
}

.Total_Amount_info .heading-holder .vr {
  background-color: #fff;
  opacity: 1;
}

.Total_Amount_info .heading-holder .info-text-holder {
  display: flex;
  align-items: center;
}

@media (min-width: 0px) and (max-width: 767px) {
.Total_Amount_info .heading-holder .info-text-holder {
  display: block;
}


.Total_Amount_info .heading-holder .vr {
  display: none;
}
}

@media (min-width: 768px) and (max-width: 991px) {
    .Total_Amount_info .count-info-holder .text-holder h3 {
      font-size: 18px;
    }
  
    .Total_Amount_info .heading-holder .info-text-holder {
      display: block;
    }
  
    .Total_Amount_info .heading-holder .vr {
      display: none;
    }
  }


@media (min-width: 992px) and (max-width: 1023px) {
  .Total_Amount_info .count-info-holder .text-holder h3 {
    font-size: 18px;
  }

  .Total_Amount_info .heading-holder .info-text-holder {
    display: block;
  }

  .Total_Amount_info .heading-holder .vr {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .Total_Amount_info .count-info-holder .text-holder h3 {
    font-size: 21px;
  }

  
}

@media (min-width: 1400px) and (max-width: 1499px) {
  .Total_Amount_info .count-info-holder .text-holder h3 {
    font-size: 19px;
  }

  
}

@media (min-width: 1500px) and (max-width: 1599px) {
  .Total_Amount_info .Border_div .sign-up-btn:after {
    height: 51px;
  }

  .Total_Amount_info .count-info-holder .text-holder h3 {
    font-size: 20px;
  }
}
