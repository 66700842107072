.Border_div .sign-up-btn {
  background: transparent;
  /* border: 3px solid #70530c; */
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  padding: 10px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  min-width: fit-content;
  position: relative;
  text-align: left;
  margin: 0px auto;
  box-shadow: inset 0px 0px 10px #ff000091;

}


.Border_div .sign-up-btn:after {
  /* content: "";
  border: 2.8px dotted #f9e67f;
  position: absolute;
  top: -5px;
  left: -4px;
  width: 100%;
  min-width: 123px;
  height: 36px;
  border-radius: 4px; */
}

@media (min-width: 1400px) and (max-width: 1499px) {
  .Border_div .sign-up-btn {
    font-size: 12px;
  }
  .Border_div .sign-up-btn:after {
    min-width: 112px;
    height: 31px;
  }
}
