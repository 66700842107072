.Login {
  position: relative;
  background-image: url("/public/Login/login-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.Login:after {
  content: "";
  position: absolute;
  background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0%
    0% no-repeat padding-box;
  height: 100vh;
  width: 100%;
  bottom: 0px;
  left: 0px;
  z-index: -1;
}

.Login .container-holder .main-content-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.Login .container-holder .main-content-holder .form-holder-container {
  display: flex;
  justify-content: center !important;
  align-items: center;
  height: 100%;
}

.Login .main-Form-holder {
  position: relative;
}

.Login .main-Form-holder .form-holder {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-47%, -44%);
  width: 340px;
}

.Login .main-Form-holder .img-holder .bg-img {
  height: 800px;
  width: 100%;
  object-fit: contain;
}


.Login .container-holder .main-content-holder .casino-img-holder {
  height: 500px;
  position: relative;
  margin-left: -30%;
}

.Login .container-holder .main-content-holder .casino-img-holder .casino-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-left: -15%;
}





/* Position each Lottie animation individually */
/* .Login .container-holder .main-content-holder .casino-img-holder .top-left {
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 10;
}

.Login .container-holder .main-content-holder .casino-img-holder .bottom-center {
  position: absolute;
  bottom: 42px;
  left: 46%;
  transform: translateX(-50%);
  z-index: 10;
}

.Login .container-holder .main-content-holder .casino-img-holder .top-right {
  position: absolute;
  bottom: 0px;
  right: 235px;
  z-index: 10;
} */


@media (min-width: 0px) and (max-width: 320px) {
  .Login .main-Form-holder .img-holder .bg-img {
    height: 600px;
    object-fit: cover;
  }

  .Login .main-Form-holder .form-holder {
    transform: translate(-50%, -47%);
    width: 221px;
  }
  .Login .container-holder .main-content-holder .casino-img-holder {
    height: 200px;
  }

  .Login .container-holder .main-content-holder .casino-img-holder .casino-img {
    margin-left: 0%;
  }

  .Login .container-holder .main-content-holder .casino-img-holder {
    margin-left: 0%;
  }
  
}

@media (min-width: 321px) and (max-width: 485px) {
  .Login .main-Form-holder .img-holder .bg-img {
    height: 512px;
    object-fit: cover;
  }

  .Login .main-Form-holder .form-holder {
    width: 250px;
  }

  .Login .container-holder .main-content-holder .casino-img-holder {
    height: 200px;
  }

  .Login .container-holder .main-content-holder .casino-img-holder .casino-img {
    margin-left: 0%;
  }

  .Login .container-holder .main-content-holder .casino-img-holder {
    margin-left: 0%;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Login .main-Form-holder .img-holder .bg-img {
    height: 600px;
    object-fit: cover;
  }

  .Login .main-Form-holder .form-holder {
    width: 250px;
  }

  .Login .container-holder .main-content-holder .casino-img-holder {
    height: 200px;
  }

  .Login .container-holder .main-content-holder .casino-img-holder .casino-img {
    margin-left: 0%;
  }

  .Login .container-holder .main-content-holder .casino-img-holder {
    margin-left: 0%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Login .main-Form-holder .img-holder .bg-img {
    height: 600px;
    object-fit: cover;
  }

  .Login .main-Form-holder .form-holder {
    width: 250px;
  }

  .Login .container-holder .main-content-holder .casino-img-holder {
    height: 200px;
  }

  .Login .container-holder .main-content-holder .casino-img-holder .casino-img {
    margin-left: 0%;
  }

  .Login .container-holder .main-content-holder .casino-img-holder {
    margin-left: 0%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Login .main-Form-holder .img-holder .bg-img {
    height: 600px;
    object-fit: cover;
  }

  .Login .main-Form-holder .form-holder {
    width: 250px;
  }

  .Login .container-holder .main-content-holder .casino-img-holder {
    height: 200px;
  }

  .Login .container-holder .main-content-holder .casino-img-holder .casino-img {
    margin-left: 0%;
  }


  .Login .container-holder .main-content-holder .casino-img-holder {
    margin-left: 0%;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .Login .main-Form-holder .img-holder .bg-img {
    height: 600px;
    object-fit: cover;
  }

  .Login .main-Form-holder .form-holder {
    width: 250px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .Login .main-Form-holder .img-holder .bg-img {
    height: 600px;
    object-fit: cover;
  }

  .Login .main-Form-holder .form-holder {
    width: 250px;
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .Login .main-Form-holder .img-holder .bg-img {
    height: 600px;
    object-fit: cover;
  }

  .Login .main-Form-holder .form-holder {
    width: 250px;
  }
}
