.Table_Heading h3 {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  margin: 0px;
  background: #fff;
  border-radius: 50px;
  width: fit-content;
  padding: 5px 16px;
  white-space: nowrap;
}

.Table_Heading {
  display: flex;
  align-items: center;
  margin: 0px 35px 10px 35px;
}

.Table_Heading:after {
  content: "";
  border-top: 1px solid #fff;
  width: 100%;
}


@media (min-width: 0px) and (max-width: 1200px) {
.Table_Heading {
  margin: 25px 0px;
}

.Table_Heading h3 {
  font-size: 15px;
}
}