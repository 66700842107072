.Sidebar {
  /* background: transparent linear-gradient(163deg, #0b0501 0%, #360701 100%) 0%
    0% no-repeat padding-box; */
    background: #14141b !important;
  box-shadow: 0px 3px 16px #f7ff0029;
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 89vh;
  margin: 15px 15px 15px 0px;
  padding: 1px 0px;
}

.Sidebar .sidebar-link-holder {
  display: block;
}

.Sidebar .sidebar-link-holder .nav-link {
  /* background: #2a0401 0% 0% no-repeat padding-box; */
  background: #000000;
  border: 1px solid #fff;
  border-radius: 5px 5px 0px 0px;
  text-align: left;
  font-size: 17px;
  font-weight: 500;
  color: #fff;
  margin: 15px 0px;
}

.Sidebar .sidebar-link-holder .nav-link.active {
  /* border: 1px solid #ecd06b; */
  background: #fff;
  color: #000;
}

.Sidebar
  .sidebar-link-holder
  .accordion-item:first-of-type
  > .accordion-header
  .accordion-button {
  background: #000;
  border: 1px solid #fff;
  border-radius: 5px 5px 0px 0px;
  text-align: left;
  font-size: 17px;
  font-weight: 500;
  color: #fff;
  padding: 10px 15px;
}

.Sidebar .sidebar-link-holder .accordion {
  --bs-accordion-bg: none;
  --bs-accordion-border-color: none;
  margin: 15px 0px;
}

.Sidebar .sidebar-link-holder .accordion-body {
  padding: 12px 12px 1px 12px;
  background: #222222;
  border-radius: 0px;
}

.Sidebar .sidebar-link-holder .accordion-body .nav-link {
  margin: 0px;
  margin-bottom: 15px;
}

.Sidebar .sidebar-link-holder .accordion .accordion-button:focus {
  box-shadow: none;
}

.Sidebar .sidebar-link-holder .accordion .accordion-button::after {
  filter: invert(1);
}
