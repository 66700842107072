.modal_holder .modal-content {
  background: #2b2b2b;
  width: 500px;
  border: none;
}

.modal_holder .modal-body {
  padding: 21px;
  position: absolute;
  top: 0px;
  width: 100%;
}

.modal_holder .main-form-holder {
  padding: 25px;
  background: #000 linear-gradient(163deg, #0b0501 0%, #000000 100%) 0% 0% no-repeat padding-box;
}

.modal_holder .form-holder {
    overflow: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    margin-bottom: 20px;
}

.modal_holder .modal-content .modal-body-holder {
  position: relative;
}

.modal_holder {
  background: #ffffff00 0% 0% no-repeat padding-box;

  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.modal_holder .modal-dialog {
  justify-content: center;
}

.modal_holder .form-holder .form-field {
  margin-bottom: 20px;
}

