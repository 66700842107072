.profile_tab_table {
  position: relative;
}

.profile_tab_table .data-table-holder {
  position: absolute;
  top: 0px;
  margin: 22px 0px;
  padding: 0px 2% 0px 2%;
  z-index: 0;
  height: 468px;
  width: 100%;
  max-height: 468px;
  overflow-y: auto;
  scrollbar-width: none;
}

.profile_tab_table .data-table-holder tbody {
  height: 423px;
}