.Header_holder {
  position: sticky;
  top: 0px;
  z-index: 9999;
}


.Header_holder .Header {
  /* background: transparent linear-gradient(94deg, #0b0501 0%, #360701 100%) 0% 0%
    no-repeat padding-box; */
  background: #12111a 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 16px #f7ff0029;
  padding: 20px 0px;
}

.Header_holder .Header .nav-link {
  background: #2a0401 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 4px #ecd06b;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 700;
  color: #ffffff;
  padding: 5px 0px;
  display: flex;
  margin-right: 10px;
}

.Header_holder .Header .nav-link.active {
  background: transparent linear-gradient(256deg, #d5a340 0%, #fcf089 51%, #b98434 100%) 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #360701;
  font-weight: 700;
}

.Header_holder .Header .nav-link.active .icon-holder .icon-img {
  filter: invert(1);
}

.Header_holder .Header .nav-link .icon-holder {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Header_holder .Header .nav-link .icon-holder .icon-img {
  width: fit-content;
  height: 17px;
  object-fit: contain;
}

.Header_holder .Header .header-side-icon-holder {
  display: flex;
}

.Header_holder .Header .header-side-icon-holder .main-side-icon {
  background: #91908e;
  box-shadow: 0px 0px 6px #000000;
  border: 2px solid #91908e;
  border-radius: 50px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 5px;
}

.Header_holder .Header .header-side-icon-holder .side-icon {
  background: #91908e;
  border-radius: 50px;
  padding: 5px 5px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Header_holder .Header .header-side-icon-holder .side-icon .icon {
  font-size: 19px;
  font-weight: 700;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Header_holder .Header .navbar-toggler-icon {
  filter: brightness(10);

}


.Header_holder .Header .navbar-toggler-icon:focus-visible {
  outline: none;

}


.Header_holder .Header .navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}

.Header_holder .Header .navbar-toggler {
  padding: 0px;
}

@media (min-width: 0px) and (max-width: 991px) {
  .Header_holder .Header .nav-link {
    margin: 10px 5px 10px 5px;
    padding: 10px 9px;
  }
}