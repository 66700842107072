@import url('https://fonts.googleapis.com/css2?family=Georgia:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700;800;900&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family:  'Roboto'  'sans-serif' !important;
}

body a{
   color: inherit;
   text-decoration: none;
}

.font-use{
      font-family: 'Georgia' !important
}
body a{
    text-decoration: none;
}



