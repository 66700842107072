.View_List .search-bar-holder {
  background: #000000 0% 0% no-repeat padding-box;
  padding: 15px 15px;
}

.View_List .search-bar-holder .form-control {
  background-color: #222222;
  border: var(--bs-border-width) solid #222222;
  border-radius: 0px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}

.View_List .search-bar-holder .form-control:focus {
  box-shadow: none;
}

.View_List .search-bar-holder .form-control::placeholder {
  color: #ffffff;
}

.View_List .search-bar-holder .css-13cymwt-control {
  background-color: #222222;
  border: var(--bs-border-width) solid #222222;
  border-radius: 0px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  min-height: 35px;
  height: 35px;
}

.View_List .search-bar-holder .css-1dimb5e-singleValue {
  color: #fff;
}

.View_List .search-bar-holder .css-13cymwt-control:focus {
  background-color: #4d0d04;
  border: var(--bs-border-width) solid #4d0d04;
  border-radius: 0px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  min-height: 35px;
  height: 35px;
}

.View_List .search-bar-holder .css-t3ipsp-control {
  background-color: #4d0d04;
  border: var(--bs-border-width) solid #4d0d04;
  border-radius: 0px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  min-height: 35px;
  height: 35px;
}

.View_List .search-bar-holder .css-t3ipsp-control:hover {
  box-shadow: none;
  border-color: #4d0d04;
}

.View_List .search-bar-holder .css-1nmdiq5-menu {
  background-color: #4d0d04;
  border: var(--bs-border-width) solid #4d0d04;
  border-radius: 0px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}

.View_List .search-bar-holder .css-1jqq78o-placeholder {
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}

.View_List .search-bar-holder .css-1cfo1cf {
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}

.View_List .search-bar-holder .search-btn {
  background: #222222 0% 0% no-repeat padding-box;
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  padding: 5px 20px;
}

/* .View_List .search-bar-holder .search-btn:hover {
  background: #360701 0% 0% no-repeat padding-box;
  border: 1px solid #ecd06b;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #ecd06b;

  padding: 5px 20px;
} */

/* .View_List .search-bar-holder .search-btn:active {
  background: #360701 0% 0% no-repeat padding-box;
  border: 1px solid #ecd06b;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #ecd06b;
  padding: 5px 20px;
} */

.View_List .search-bar-holder .reset-btn {
  background: #222222;
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  padding: 5px 20px;
}

/* .View_List .search-bar-holder .reset-btn:hover {
  background: transparent
    linear-gradient(249deg, #d5a340 0%, #fcf089 51%, #b98434 100%) 0% 0%
    no-repeat padding-box;
  border: 1px solid #360701;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #360701;
  padding: 5px 20px;
} */

/* .View_List .search-bar-holder .reset-btn:active {
  background: transparent
    linear-gradient(249deg, #d5a340 0%, #fcf089 51%, #b98434 100%) 0% 0%
    no-repeat padding-box;
  border: 1px solid #360701;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #360701;
  padding: 5px 20px;
} */

.View_List .search-bar-holder .col-holder {
  width: fit-content;
}

.View_List .search-bar-holder .reset-btn {
  margin: 5px 0px;
}

.View_List .search-bar-holder .search-btn {
  margin: 5px 0px;
}

.View_List .search-bar-holder .form-field {
  margin: 5px 0px;
}

.View_List .search-bar-holder .css-13cymwt-control {
  margin: 5px 0px;
}

.View_List .user-list-table {
  position: relative;
}

.View_List .data-table-holder {
  position: absolute;
  top: 0px;
  margin: 26px 0px;
  padding: 0px 2% 0px 2%;
  z-index: 0;
  height: 550px;
  width: 100%;
  max-height: 550px;
  overflow-y: auto;
  scrollbar-width: none;
}

.View_List .user-list-table .action-holder {
  display: flex;
  justify-self: center;
}


.View_List .user-list-table .action-holder .icon-holder {
  background: #000000;
  border: 1px solid #ffffff;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
}
.View_List .user-list-table .action-holder .icon-holder svg{
  font-size: 11px;
}
.View_List .user-list-table .text-red{
color: #fff !important;
}