body {
  /* background-image: url("/public/Bg/body-bg.png"); */
  background-color: #010110 !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 100vh;
}

.Dashboard .main-content-holder {
  padding: 15px;
  height: 89vh;
  overflow: hidden;
  overflow-y: auto;
  margin: 15px 0px;
}

.form-holder .form-label {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}

.form-holder .form-control::placeholder {
  color: #fff;
}

.form-holder .form-control {
  background: #4141413b;
  border: 1px solid #fff;
  border-radius: 5px;
  backdrop-filter: blur(6px);
  font-size: 15px;
  font-weight: 500;
  color: #fff !important;
}

.form-holder .form-control:focus {
  background: #4141413b 0% 0% no-repeat padding-box;
  background-color: #4141413b;
  box-shadow: none;
  border: 1px solid #fff;
}

/* .form-holder .form-control:-webkit-autofill {
  background-color: transparent !important;
  color: #fff !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
} */

.modal_holder .search-btn {
  background: #222222 0% 0% no-repeat padding-box;
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  padding: 5px 20px;
  width: 100%;
}

/* .modal_holder .search-btn:hover {
  background: #360701 0% 0% no-repeat padding-box !important;
  border: 1px solid #ecd06b !important;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #ecd06b !important;
  padding: 5px 20px;
} */

/* .modal_holder .search-btn:active {
  background: #360701 0% 0% no-repeat padding-box !important;
  border: 1px solid #ecd06b !important;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #ecd06b !important;

  padding: 5px 20px;
} */

.modal_holder .reset-btn {
  background: #222222;
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  width: 100%;
  padding: 5px 20px;
}

/* .modal_holder .reset-btn:hover {
  background: transparent
    linear-gradient(249deg, #d5a340 0%, #fcf089 51%, #b98434 100%) 0% 0%
    no-repeat padding-box !important;
  border: 1px solid #360701 !important;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #360701 !important;
  padding: 5px 20px;
} */

/* .modal_holder .reset-btn:active {
  background: transparent
    linear-gradient(249deg, #d5a340 0%, #fcf089 51%, #b98434 100%) 0% 0%
    no-repeat padding-box !important;
  border: 1px solid #360701 !important;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #360701 !important;
  padding: 5px 20px;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

@media (min-width: 0px) and (max-width: 991px) {
  /* Off-canvas sidebar styling */
  .Dashboard .offcanvas-sidebar {
    position: fixed;
    /* top: 0; */
    left: -250px; /* Hidden by default */
    width: 250px;
    height: 100%;
    transition: left 0.3s ease;
    z-index: 1050;
  }

  .Dashboard .offcanvas-sidebar.open {
    left: 0; /* Slide in when open */
  }

  .Dashboard .sidebar-toggle .icon-rotate {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
  }

  /* Sidebar toggle button */
  .Dashboard .sidebar-toggle {
    position: fixed;
    left: 0px;
    z-index: 1060;
    width: fit-content;
    background: transparent
    linear-gradient(
      180deg,
      #ffffff 0%,
      #f9e67f 31%,
      #cba049 57%,
      #b98434 100%
    )
    0% 0% no-repeat padding-box;
  border: 2px solid #70530c;
    border-radius: 0px 50px 50px 0px;
    font-size: 15px;
    color: #000;
    margin: 0px 0px 15px 0px;
    padding: 0px 11px;
  }

  .Dashboard .sidebar-toggle.active {
    position: fixed;
    left: 0px;
    z-index: 1060;
    width: fit-content;
    background: transparent
    linear-gradient(
      180deg,
      #ffffff 0%,
      #f9e67f 31%,
      #cba049 57%,
      #b98434 100%
    )
    0% 0% no-repeat padding-box;
  border: 2px solid #70530c;
    border-radius: 0px 50px 50px 0px;
    font-size: 15px;
    color: #000;
    margin: 0px 0px 15px 0px;
    padding: 0px 11px;
  }

  .Dashboard .sidebar-toggle:hover {
    position: fixed;
    left: 0px;
    z-index: 1060;
    width: fit-content;
    border-radius: 0px 50px 50px 0px;
    font-size: 15px;
    color: #000;
    margin: 0px 0px 15px 0px;
    padding: 0px 11px;
    background: transparent
      linear-gradient(
        180deg,
        #ffffff 0%,
        #f9e67f 31%,
        #cba049 57%,
        #b98434 100%
      )
      0% 0% no-repeat padding-box;
    border: 2px solid #70530c;
  }
}


::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar:horizontal {
  height: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #000;
}
::-webkit-scrollbar-track:horizontal {
  background: #fff;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:horizontal{
  /* background: transparent linear-gradient(249deg, #d5a340 0%, #fcf089 51%, #b98434 100%) 0% 0% no-repeat padding-box; */
  background: #525252;
  border-radius: 5px;
   }

