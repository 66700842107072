.data-table-holder th {
  text-wrap-mode: nowrap;
  color: #fff !important;
  font-family: "Georgia";
  font-weight: 500;
  font-size: 18px;
  border-right: 1px solid #707070 !important;
}



.data-table-holder .table {
  --bs-table-bg: none !important;
  text-align: center;
  margin: 0px;
  width: fit-content;
  background-color: #16161e;
  border: 1px solid #707070;
}


.data-table-holder thead {
  background: #000;
}

.data-table-holder td {
  color: #fff !important;
  font-weight: 500;
  font-size: 16px;
  border-right: 1px solid #707070 !important;
}

.data-table-holder tbody, .data-table-holder td, .data-table-holder tfoot, .data-table-holder th, .data-table-holder thead, .data-table-holder tr {
  border: none;
  text-wrap-mode: nowrap;
  width: 300px;
  min-width: 300px;
  max-width: 100%;
}

.data-table-holder .border-none-holder {
  border: transparent !important;
}



.data-table-holder .form-check-input:focus {
  border-color: #ecd06c;
  box-shadow: none;;
}

.data-table-holder  .form-switch .form-check-input {
  --bs-form-check-bg: #ffffff;
  border: var(--bs-border-width) solid #ecd06c;
  --bs-form-switch-bg: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAAAAXNSR0IArs4c6QAAAARzQklUCAgICHwIZIgAAAUnSURBVGhD5VtvaFtVFD/nJf3j3D/JS6tOB7NKu0mWxA3Z5ocpTtwcgv1QLIjQCY4lUWSg2KFCVcbmp25sTaTC1A+iFlG/aDdU2GCwDpWkrdaVmckYztW8WDfRrl3yjuema7um+feSm/dMvF/avNz7+53fPfed++8EoQxFG3quhZL0OCLdxfC3AmEjITUC8f+iIFxCwjHxF4DGiPBcjV35dLnrUFS2OSgLcFqU3o6AbWz4mmJwCSjCndJnV+x9ssSWLFCLBFqJqAsR1xYjKmsbgu8A9L2qN/R5KbhFCxwf8m1O6NjNHvOWYkDetkKoAp2qu+ebvHUzVDAscDzs9yQRuxnrwWIIi25D9DXZqNO5NvS9EQxDAnk4vspB4Q2OEobaGTEod13i1xReU73BvYViFmQoXdh9Uzw++Q4Le6pQ4PLWow8cjrpn8c7uiXw8eQXGRnbeBlP2L8r+ruWzNO17dmUYahPbnWt6f8vVNKfA+FBgIyXhEw77txvkN6c6wUUb0PZbvMFINsKsAumXjnrt8qJT7DmPOdYWxyI8qS77ZxOueu9qJoSsArVwoI8911YcrbmtWOTHTk+wvWCBWsTfyQFln7lmlspGe1RPcH86ygIPasP+1ZCAYZ4JbKVSmtqeOFrYwaW6gj/dyLtQYCRwlCs8aqpx8siOqZ6erVkFamHfE4DKZ/L4zEfixfo2hzsonJQqsx6kH9tq41MNZ/nJSvPNkshIMOLwOF2IXfo8gZUZWDJ3DBLsdnh7DswKvDK6U52asJ9jhy6R2JfWQRFcqV10rWlpc6+WGqLxQf9W3lX3W2eRfGbW85DTe/h4SqAW9h/gaeEF+TSWIr7FEbVzWmDEf4aHZ7Ol5kgnp1Ge+FtQnKWATvMmR+lcVgEquBqrKXou7Efag7GI/yPeMTxpVSeXk1cswtmDFb00y9c/xzAWDvBZJLjz1azQ70/zEA1c4lDaWKECcptNcF4IvMoC66pRIAFMIk/yf/Ikv6waBQLRZTFNVOEkP+MuGuUg4z/O9wqbq9GDfGdy4n8wD1bnQnt6QBIdFEGmg4PMu9U4RFngDg4yu1YAKBesu1ApV9fyG1ibWJHaLlXpauY07wc3XN8PBsSB6cvl6ksrcNl/rzu9wa7rRxa+DUTKKSsMKRcnor7R4Q4NzB4bVtWKhlcwfEm6XHTenMCKvI/I5v+5e4q5g9+zz9dpf+vnK31nwZvc39WbbSvxnkOT8zyYiqYR3y4EJVSu98IMXALd5/SE3p7hmnf5wukuijYY+5kfrjLDGPkc9IPD3eCeObZf4EHx4I/BQLtO8KF8chMQSW9NTxzKeMPL5zRH2JwdJpgkj4LgfdXb05EOmFEgD1V7PBL7kmPsI/IsKCMSJwk5PA3beGgmChKYCjhnnlkCE/Unpeegydc5TPUTDzhbjvyVCTpnGsn0Qtw2wA3vkG+XDET6lXTlfud9hy9mQ6vYRCDe7A3U10Dr4nuDnHOaveQVKJr+11K5xIm1mkg8jet7r+UbBwUJnAHh9eorHHjetGzvyJkUfEj9ksMTFNmOBRVDAlPBZ8i3DpO4n08BthTEIKuSGemUN9qqDQYeBh1YKKyXpSEjjtkJselGiJVPUqcXeTpZJ1Uo0bc8SvbxrryktBbDQzSbCDGlICp81w+PcZb9FvbsUmOCaZwAv0Ki/np7Tf9i18ExY+0z15YmMB0+HvZvAqRmQmzihIAmTu24mz83peoRRvlnBlH+aUGUg0YUUB8Ru28ZgtIx/gV5/NY1x5YpogAAAABJRU5ErkJggg==);
  filter: grayscale(10);
}

.data-table-holder  .form-switch .form-check-input:checked {
  background-color: #ecd06c;
  border-color: #ecd06c;
  --bs-form-switch-bg: url("/public/Icon/browncircle.png") ;
}

.data-table-holder .table-striped>tbody>tr:nth-of-type(even) {
  background-color: #232324;
}

.data-table-holder tbody {
  display: block;
  height: 240px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
}



.data-table-holder thead tr {
	display: block;
	position: relative;
}
