.Profile_info {
  background: var(--bs-gray-dark) 0% 0% no-repeat padding-box;
  padding: 24px;
  border-radius: 7px;
}

.Profile_info .profile-col-holder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Profile_info .profile-holder .profile-img {
  width: 100px;
  height: 100px;
  aspect-ratio: 1/1;
  padding: 10px;
  border-radius: 50%;
}

.Profile_info .profile-holder {
  border: 3px solid #000;
  border-radius: 50%;
  background: #fbee87 0% 0% no-repeat padding-box;
}

.Profile_info .heading-holder h3 {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}

.Profile_info .heading-holder p {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 7px;
}

.Profile_info .count-info-holder {
  background: #14141b;
  box-shadow: 0px 0px 6px #00000082;
  border-radius: 6px 0px 0px 6px;
  height: 100%;
  padding: 12px 0px;
}

.Profile_info .count-info-holder .border-holder {
  border-right: 2px solid #fff;
}

.Profile_info .count-info-holder .text-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.Profile_info .count-info-holder .text-holder h3 {
  font-size: 40px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 8px;
}

.Profile_info .count-info-holder .text-holder p {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 0px;
}




@media (min-width: 0px) and (max-width: 767px) {
  .Profile_info .heading-holder h3 {
    font-size: 22px;
  }

  .Profile_info .count-info-holder .text-holder h3 {
    font-size: 30px;
  }

  .Profile_info .count-info-holder .text-holder p {
    font-size: 14px;
  }

  .Profile_info .profile-holder {
    margin-bottom: 11px;
}


.Profile_info .count-info-holder .border-holder {
  border-right: 0px solid #ecd06b;
}

}


@media (min-width: 768px) and (max-width: 991px) {
  .Profile_info .heading-holder h3 {
    font-size: 22px;
  }

  .Profile_info .count-info-holder .text-holder h3 {
    font-size: 30px;
  }

  .Profile_info .count-info-holder .text-holder p {
    font-size: 14px;
  }

  .Profile_info .profile-holder {
    margin-bottom: 11px;
}
}


@media (min-width: 992px) and (max-width: 1023px) {
  .Profile_info .heading-holder h3 {
    font-size: 22px;
  }

  .Profile_info .count-info-holder .text-holder h3 {
    font-size: 30px;
  }

  .Profile_info .count-info-holder .text-holder p {
    font-size: 14px;
  }


  .Profile_info .profile-holder .profile-img {
    width: 75px;
    height: 75px;
}
}




@media (min-width: 1024px) and (max-width: 1199px) {
  .Profile_info .heading-holder h3 {
    font-size: 22px;
  }

  .Profile_info .count-info-holder .text-holder h3 {
    font-size: 30px;
  }

  .Profile_info .count-info-holder .text-holder p {
    font-size: 14px;
  }


  .Profile_info .profile-holder .profile-img {
    width: 75px;
    height: 75px;
}
}


@media (min-width: 1200px) and (max-width: 1299px) {
  .Profile_info .heading-holder h3 {
    font-size: 22px;
  }

  .Profile_info .count-info-holder .text-holder h3 {
    font-size: 30px;
  }

  .Profile_info .count-info-holder .text-holder p {
    font-size: 14px;
  }


  .Profile_info .profile-holder .profile-img {
    width: 75px;
    height: 75px;
}
}


@media (min-width: 1300px) and (max-width: 1399px) {
  .Profile_info .heading-holder h3 {
    font-size: 22px;
  }

  .Profile_info .count-info-holder .text-holder h3 {
    font-size: 30px;
  }

  .Profile_info .count-info-holder .text-holder p {
    font-size: 14px;
  }


  .Profile_info .profile-holder .profile-img {
    width: 75px;
    height: 75px;
}
}
