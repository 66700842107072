

.Dashboard  .table-holder .main-table-holder{
position: relative;
}



.Dashboard  .table-holder .main-table-holder .data-table-holder {
    position: absolute;
    top: 0px;
    margin: 26px 0px;
    padding: 0px 4% 0px 4%;
    z-index: 0;
    height: 296px;
    width: 100%;
    max-height: 296px;
    overflow-y: auto;
    scrollbar-width: none;
}

  
.Dashboard  .view-all-btn {
    width: fit-content;
    margin-left: auto;
}