.Border_btn .sign-up-btn {
  background: transparent
  linear-gradient(180deg, #ffffff 0%, #f9e67f 31%, #cba049 57%, #b98434 100%)
  0% 0% no-repeat padding-box;
  border: 7px solid #70530c;
  font-size: 16px;
  font-weight: 600;
  color: #360701;
  padding: 5px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 168px;
  min-width: fit-content;
  margin: 0px auto;
  margin-top: 50px;
  text-wrap-mode: nowrap;
  position: relative;
}

.Border_btn .sign-up-btn:hover {
  background: transparent
    linear-gradient(180deg, #ecd06b 0%, #ffdb19 31%, #ffab00 57%, #fb9b0b 100%)
    0% 0% no-repeat padding-box;
  border: 7px solid #b58e00;
  color: #360701;
}

.Border_btn .sign-up-btn:active {
  background: transparent
    linear-gradient(180deg, #ecd06b 0%, #ffdb19 31%, #ffab00 57%, #fb9b0b 100%)
    0% 0% no-repeat padding-box;
  border: 7px solid #b58e00;
  color: #360701;
}

.Border_btn .btn:first-child:active {
  background: transparent
    linear-gradient(180deg, #ecd06b 0%, #ffdb19 31%, #ffab00 57%, #fb9b0b 100%)
    0% 0% no-repeat padding-box;
  border: 7px solid #b58e00;
  color: #360701;
}

.Border_btn .sign-up-btn:after {
  content: "";
  border: 2.8px dotted #f9e67f;
  position: absolute;
  top: -5px;
  left: -4px;
  width: 103%;
  min-width: 164px;
  height: 44px;
  border-radius: 4px;
}

.Border_btn .sign-up-btn span {
  margin-left: 13px;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

