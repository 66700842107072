.Modal_Heading h3 {
  font-size: 17px;
  font-weight: 600;
  color: #000000;
  margin: 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 50px;
  width: fit-content;
  padding: 5px 16px;
  white-space: nowrap;
  font-family: unset !important;
}
  
  .Modal_Heading {
    display: flex;
    align-items: center;
    margin: 0px 35px 10px 35px;
  }

  .Modal_Heading:before {
    content: "";
    border-top: 1px solid #fff;
    width: 100%;
  }
  
  
  .Modal_Heading:after {
    content: "";
    border-top: 1px solid #fff;
    width: 100%;
  }
  
  
  @media (min-width: 0px) and (max-width: 1200px) {
  .Modal_Heading {
    margin: 25px 0px;
  }
  
  .Modal_Heading h3 {
    font-size: 15px;
  }
  }